import React, {useState, useEffect} from 'react';
import axios from 'axios';
import '../css/EtcOption.css';

function EtcOption() {
    const MAX_SELECT_COUNT = 6;

    const [loading, setLoading] = useState(true);
    const [etcSubs, setEtcSubs] = useState([]);
    const [stoneOptions, setStoneOptions] = useState([]);
    const [onlySelectedClass, setOnlySelectedClass] = useState(false);
    const [etcClasses, setEtcClasses] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState("유물");
    const [selectedClass, setSelectedClass] = useState('all');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showMaxSelectMsg, setShowMaxSelectMsg] = useState(false);
    const [searchEtcOptionsText, setSearchEtcOptionsText] = useState('');
    const [searchText, setSearchText] = useState('');

    // 컴포넌트가 마운트될 때 데이터를 가져오도록 호출
    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://mococo.me/api/auction/etc-subs');
            setEtcSubs(response.data.map(item => ({...item, activationLevel: 3, readLevel: 0})));
            setEtcClasses(response.data.map(etcSub => etcSub.LostarkClass).filter(etcSubClassName => etcSubClassName !== ''));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const selectOption = (subId) => {
        if (selectedOptions.length >= MAX_SELECT_COUNT && isNewOptionSelected(subId)) {
            setShowMaxSelectMsg(true);
            setTimeout(() => setShowMaxSelectMsg(false), 2000);
            return;
        }
        setSearchEtcOptionsText('');
        setSelectedOptions(prev => {
            const isSelected = prev.some(option => option.Id === subId);
            if (isSelected) {
                return prev.filter(sub => sub.Id !== subId);
            } else {
                return [...prev, etcSubs.find((sub) => sub.Id === subId)];
            }
        })
    }

    const isNewOptionSelected = (optionId) => {
        return !selectedOptions.some((option) => option.Id === optionId);
    };

    const filterByLostarkClass = (selectedClass) => {
        setSelectedClass(selectedClass);
        renderOptions();
    };

    const filterByGrade = (selectedGrade) => {
        setSelectedGrade(selectedGrade);
    };

    const renderOptions = () => {
        let searchingSubs = etcSubs.filter(sub => sub.Text.includes(searchEtcOptionsText) || selectedOptions.some(selectedOption => selectedOption.Id === sub.Id));
        const filteredSubs = searchingSubs.filter(sub => sub.LostarkClass === selectedClass);
        const restSubs = searchingSubs.filter(sub => {
            if (onlySelectedClass && selectedClass !== 'all') {
                return sub.LostarkClass === '';
            } else {
                return sub.LostarkClass !== selectedClass
            }
        });
        const sortedFilteredSubs = filteredSubs.toSorted((a, b) => a.Text.localeCompare(b.Text));
        const sortedRestSubs = restSubs.toSorted((a, b) => a.Text.localeCompare(b.Text));
        let allSubs = [...sortedFilteredSubs, ...sortedRestSubs];

        return allSubs.map((sub) => (
            <div
                key={sub.Id}
                className={`
                engraveOption-box 
                ${selectedOptions.some((selectedOption) => selectedOption.Id === sub.Id) ? 'selected' : ''} 
                ${selectedClass === sub.LostarkClass ? 'contains-class' : ''}
                `}
                onClick={() => selectOption(sub.Id)}>
                <p>{sub.Text}</p>
                <span className="lostark-class">{sub.LostarkClass}</span>
            </div>
        ));
    };

    function removeAllOption() {
        setSelectedOptions([])
    }

    function removeOptions(optionId) {
        setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption.Id !== optionId))
    }

    function changeLevel(optionId, changedLevel) {
        setSelectedOptions(prevOptions => {
            return prevOptions.map(option => {
                if (option.Id === optionId) {
                    return {...option, activationLevel: changedLevel};
                }
                return option;
            });
        });
    }

    const getSelectedOptions = () => {
        return (<>{selectedOptions.map((option) => (
            <ul className="left-float-list">
                <li className="left-float-list" key={option.Id}><span>{option.Text}</span>
                    <input
                        type="number"
                        onChange={(e) => changeLevel(option.Id, parseInt(e.target.value))}
                        min={1}
                        max={3}
                        defaultValue={option.activationLevel}
                    />
                    <button className="option-delete-btn" onClick={() => removeOptions(option.Id)}>해제</button>
                </li>
            </ul>
        ))}</>);
    }

    const getReadedEngrave = () => {
        return <>{selectedOptions.map((option) => (
            <ul className="left-float-list">
                <li className="left-float-list" key={option.Id}><span>{option.Text}</span>
                    <input type="number"
                           onChange={(e) => changeReadedEngrave(option.Text, parseInt(e.target.value))}
                           min={0}
                           max={3}
                           defaultValue={0}/>
                    <button className="option-delete-btn" onClick={() => removeOptions(option.Id)}>해제</button>
                </li>
            </ul>
        ))}</>;
    }

    function changeReadedEngrave(optionName, readLevel) {
        if (readLevel === 0) {
            setSelectedOptions(selectedOptions.filter(readOption => readOption.Text !== optionName));
        } else {
            setSelectedOptions(prevOptions => {
                return prevOptions.map(option => {
                    if (option.Text === optionName) {
                        return {...option, readLevel: readLevel};
                    }
                    return option;
                });
            });
        }
    }

    function getActivationFromReadedEngraveLevel(readLevel) {
        return (readLevel + 1) * 3;
    }

    function selectLostarkClass() {
        return <>
            <label style={{fontSize: "19px", fontWeight: "bold"}} htmlFor="lostark-class-select">직업 선택 : </label>
            <select id="lostark-class-select" onChange={(e) => filterByLostarkClass(e.target.value)}>
                <option value="all">직업을 선택해주세요</option>
                {[...new Set(etcClasses)].sort((a, b) => a.localeCompare(b)).map((lostarkClass) => (
                    <option key={lostarkClass} value={lostarkClass}>{lostarkClass}</option>))}
            </select>
            <input style={{color: "white"}} type="checkbox" name="onlyClass"
                   onChange={(e) => showOnlySelectedClass(e.target.checked)}/>
            <label htmlFor="onlyClass">다른 직업 각인들을 제외합니다</label>
        </>;
    }

    function showOnlySelectedClass(activate) {
        console.log(activate);
        if (activate) {
            setOnlySelectedClass(true)
        } else {
            setOnlySelectedClass(false)
        }
        renderOptions();
    }

    function selectGrade() {
        const grades = ["유물", "고대"];
        return <>
            <label style={{fontSize: "19px", fontWeight: "bold"}} htmlFor="lostark-grade-select">악세서리 등급 선택 : </label>
            <select id="lostark-grade-select" onChange={(e) => filterByGrade(e.target.value)}>
                {grades.map((selectedGrade) => <option key={selectedGrade}
                                                       value={selectedGrade}>{selectedGrade}</option>)}
            </select>
        </>;
    }

    function maxOptionSelectedMessage() {
        return <>
            {showMaxSelectMsg && (
                <div className="max-select-msg" id="maxSelectMsg">
                    최대 6개만 선택 가능합니다.
                </div>
            )}
        </>;
    }

    function showStoneOptions() {
        const sortedStoneOptions = stoneOptions.toSorted((a, b) => {
            const engraveComparison = a.MinimumNeedEngrave1.localeCompare(b.MinimumNeedEngrave1);
            if (engraveComparison !== 0) return engraveComparison;

            if (a.MinimumNeedValue1 < b.MinimumNeedValue1) return -1;
            if (a.MinimumNeedValue1 > b.MinimumNeedValue1) return 1;

            if (a.MinimumNeedEngrave2 === null) {
                return -1;
            }

            return a.MinimumNeedEngrave2.localeCompare(b.MinimumNeedEngrave2);
        });

        return (
            <div className="stone-options">
                <input
                    type="text"
                    value={searchText}
                    style={{
                        margin: "15px 0px 15px 0px",
                        padding: "10px",
                        fontSize: "18px",
                        width: "320px",
                        textAlign: "center"
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="검색결과에서 찾으려는 각인 입력"
                />
                <button className="option-delete-btn" onClick={() => removeAllStoneOptions()}>모든 결과 그만 보기</button>
                <table className="stone-options-table">
                    <thead>
                    <tr>
                        <th>각인 1 ( 최소 활성도)</th>
                        <th>각인 2 ( 최소 활성도 )</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedStoneOptions
                        .filter(stoneOption =>
                            (stoneOption.MinimumNeedEngrave1.includes(searchText)) ||
                            (stoneOption.MinimumNeedEngrave2 && (stoneOption.MinimumNeedEngrave2.includes(searchText)))
                        )
                        .map(stoneOption => (
                            <tr key={stoneOption.Id}>
                                <td>{stoneOption.MinimumNeedEngrave1} ( {stoneOption.MinimumNeedValue1} )</td>
                                <td>{(stoneOption.MinimumNeedEngrave2 === null) ? '필요 없음' : `${stoneOption.MinimumNeedEngrave2} ( ${stoneOption.MinimumNeedValue2} )`}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        );
    }

    function removeAllStoneOptions() {
        setStoneOptions([]);
    }

    const searchStones = async () => {
        try {
            if (selectedOptions.filter(readOption => readOption.readLevel > 0).length > 2) {
                alert("각인은 최대 2개까지만 읽을 수 있습니다.")
                return
            }

            const goals = selectedOptions.map(option => {
                return {
                    engraveName: option.Text,
                    activationLeft: option.activationLevel * 5
                };
            });

            if (goals === null || goals.length === 0) {
                alert("목표 각인을 클릭해주세요")
                return;
            }

            const response = await axios.post('https://mococo.me/api/auction/stone', {
                characterOptions: selectedOptions.filter(readOption => readOption.readLevel > 0).map(readOption => {
                    return {
                        engraveName: readOption.Text,
                        activationLeft: getActivationFromReadedEngraveLevel(readOption.readLevel)
                    }
                }),
                goals: goals,
                grade: selectedGrade
            });

            if (response.data === null || response.data.length === 0) {
                console.log("Disable")
                if (selectedGrade === '유물') {
                    alert("유물 등급으로는 불가능한 각인이에요 고대 등급으로 한번 찾아보세요");
                } else {
                    alert("해당 각인은 10 10돌로도 맞출 수 없어요...");
                }
            }
            console.log(response.data)
            setStoneOptions(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    return (
        <div className={"etcOption"}>
            <h1 style={{fontSize: "50px"}}>🗿돌 깍는 모코콩</h1>
            {(stoneOptions.length === 0) ? '' : showStoneOptions()}
            <div className="dropdown-content">{selectLostarkClass()}</div>
            <div className="dropdown-content">{selectGrade()}</div>
            {(selectedOptions.length === 0) ? '' : <h1 style={{marginBottom: '10px'}}>활성화 할 각인, 레벨</h1>}
            {(selectedOptions.length === 0) ? '' :
                <button className="option-delete-btn" onClick={() => removeAllOption()}>모두 해제</button>}
            <div className="selected-option">{getSelectedOptions()}</div>
            {(selectedOptions.length === 0) ? '' :
                <h2 style={{marginBottom: '10px'}}>이미 활성화한 각인, 레벨 ( 비활성 : 0, 희귀 : 1 , 영웅 : 2 , 전설 : 3 )</h2>}
            <div>{getReadedEngrave()}</div>
            <p style={{fontWeight: "bold", margin: "5px 10px 5px 10px"}}>악세서리의 각인의 활성도는 유물 ( 5 3 ) , 고대 ( 6 3 ) 인 경우만을
                고려합니다.</p>
            <button className="search-stone-btn" onClick={() => searchStones()}
                    style={{width: "400px", fontSize: "18px", margin: "20px"}}>
                선택한 각인을 위한 최소 돌들 찾기
            </button>
            {maxOptionSelectedMessage()}
            <input
                type="text"
                value={searchEtcOptionsText}
                style={{marginBottom: "15px", padding: "10px", fontSize: "18px", width: "220px", textAlign: "center"}}
                onChange={(e) => setSearchEtcOptionsText(e.target.value)}
                placeholder="찾으려는 각인 입력"
            />
            {loading && <div style={{margin: "30px", fontSize: "25px"}}> 🙇 로딩 중입니다 잠시만 기다려주세요 🙇 </div>}
            {!loading && (
                <div className="engraveOptions-container">
                    {renderOptions()}
                </div>
            )}
        </div>
    );
}

export default EtcOption;
